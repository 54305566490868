// App.js
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Filters from '../../Components/Filters/filters';
import FlightCard from '../../Components/FlightCard/flightCard';
import Sidebar from '../../Components/SideBar/sideBar';
import Pagination from '../../Components/Pagination/Pagination';
import { constant } from "../../constant"
// import './App.css';
import '../../Resources/css/listing.css'
import ModifySearch from '../../Components/ModifySearch/ModifySearch';
import imgfilter from '../../Resources/assest/img/listing/filter.png'

const Listing = () => {
    const location = useLocation();
    const { updatedFormValues } = location.state || {};
    // console.log('updatedFormValues', updatedFormValues);
    const [isLoding, setIsLoding] = useState(true);
    const [flights, setFlights] = useState([]);
    const [metaFlights, setMetaFlights] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredFlights, setFilteredFlights] = useState(flights);
    const [resultsCount, setResultsCount] = useState(0);
    const [filteredResultsCount, setFilteredResultsCount] = useState(0);
    const [allMeta, setAllMeta] = useState([])
    const [progress, setProgress] = useState(0); // State for progress
    const [modifyClick, setModifyClick] = useState(false);
    const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
    const totalExpectedResults = 2000; // Replace with actual total if known
    const flightsPerPage = 50; // Set how many flights to display per page


    useEffect(() => {
        const fetchData = async () => {

            try {
                setIsLoding(true);
                // console.log("Form data being sent:", updatedFormValues);

                // First API Call: Fetch itineraries
                // First API Call: Fetch itineraries
                // console.log("Fetching itineraries...");
                const onlyDeeplinkResponse = await fetch(`${constant.baseUrl}api/website/get-only-deeplink`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedFormValues),
                });
        
                // Check response status
                if (!onlyDeeplinkResponse.ok) {
                    console.error("Failed to fetch onlyDeeplink. Status:", onlyDeeplinkResponse.status);
                    return;
                }
        
                // Parse response as JSON
                const onlyDeeplink = await onlyDeeplinkResponse.json();
                // console.log("onlyDeeplink response:", onlyDeeplink);
        
                // Set `onlyDeeplink` to state
                setAllMeta(onlyDeeplink);




                
                const itineraryResponse = await fetch(`${constant.baseUrl}api/website/itineraries`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedFormValues),  // Pass the form data
                });
                // console.log("itineraryResponse", itineraryResponse)

                if (!itineraryResponse.ok) {
                    console.error("Failed to fetch itineraries. Status:", itineraryResponse.status);
                    // return;
                }

                const itineraryData = await itineraryResponse.json();
                // console.log("Itinerary response received:", itineraryData);

                // Set flights from the itineraries API response
                if (itineraryData.uniqueItineraries) {
                    setFlights(itineraryData.uniqueItineraries);
                    setResultsCount(itineraryData.uniqueItineraries.length);  // Set results count based on the initial data
                    // console.log("Flights set from itineraries API:", itineraryData.uniqueItineraries);
                }

                const metaResponse = await fetch(`${constant.baseUrl}api/website/get-meta-deep-link`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedFormValues),  // Pass the form data
                });
                const metaData = await metaResponse.json();
                // console.log("metaData",metaData); // Check what the structure of the metaData is

                // Ensure the data exists before setting it to state
                if (metaData && Array.isArray(metaData)) {
                    const filteredMetaData = metaData.filter(item =>
                      item.deeplink && item.deeplink.includes('https://www.')
                    );
                  
                    if (filteredMetaData.length > 0) {
                      // Update the date format in the deeplink
                      const updatedMetaData = filteredMetaData.map(item => {
                        const url = new URL(item.deeplink);
                        const searchParams = url.searchParams;
                  
                        // Helper function to convert date format
                        const formatDate = date => {
                          const [year, month, day] = date.split('-');
                          return `${month}/${day}/${year}`;
                        };
                  
                        // Update departDate
                        if (searchParams.has('departDate')) {
                          const newDepartDate = formatDate(searchParams.get('departDate'));
                          searchParams.set('departDate', newDepartDate);
                        }
                  
                        // Update returnDate
                        if (searchParams.has('returnDate')) {
                          const newReturnDate = formatDate(searchParams.get('returnDate'));
                          searchParams.set('returnDate', newReturnDate);
                        }
                  
                        // Return updated item with the modified deeplink
                        return { ...item, deeplink: url.toString() };
                      });
                  
                      setMetaFlights(updatedMetaData); // Update state with the modified data
                    } else {
                      console.error("No matching data found with 'https://www.' in deeplink.");
                    }
                  } else {
                    console.error("No data found in meta response.");
                  }
                  

                const response = await fetch(`${constant.baseUrl}api/website/get-deep-link`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedFormValues),
                });

                // console.log("Response status:", response.status); // Check if the response is successful
                // console.log("Response body exists:", !!response.body);

                if (!response.body) {
                    console.error("ReadableStream not supported or response body is null");
                    return;
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder("utf-8");
                let buffer = ""; // Buffer to accumulate incoming chunks
                let flights = []; // To store accumulated flight data
                let itineraryBuffer = ""; // Buffer for the current itinerary

                while (true) {
                    const { done, value } = await reader.read();
                
                    if (done) {
                        // console.log("Stream has finished.");
                        setProgress(100);
                        break;
                    }
                
                    // console.log("Received chunk from stream:", value);
                
                    // Decode the chunk to a string and add to the buffer
                    buffer += decoder.decode(value, { stream: true });
                    // console.log("Buffer after decoding chunk:", buffer);
                
                    // Split the buffer into individual chunks based on the incoming format
                    const chunks = buffer.split("data:");
                
                    for (let chunk of chunks) {
                        if (!chunk.trim()) continue; // Ignore empty chunks
                
                        // Accumulate itineraries
                        itineraryBuffer += chunk.trim(); // Accumulate current itinerary data
                
                        // Check for complete itinerary JSON object
                        const closingBraces = (itineraryBuffer.match(/\}/g) || []).length; // Count closing braces
                        const openingBraces = (itineraryBuffer.match(/\{/g) || []).length; // Count opening braces
                
                        // If we have matching braces, we have a complete itinerary
                        if (closingBraces > 0 && closingBraces === openingBraces) {
                            try {
                                // Parse the accumulated itinerary
                                const flightData = JSON.parse(itineraryBuffer);
                                // console.log("Parsed flight data:", flightData);
                                // Check if flightItineraries exist in the flightData
                                if (flightData.uniqueItineraries) {
                                    
                
                                    // Sort itineraries by price before setting them in state
                                    // const sortedItineraries = flightData.uniqueItineraries.sort((a, b) => {
                                    //     return a.price.totalAmount - b.price.totalAmount; // Ascending order by price
                                    // });

                                    const sortedItineraries = flightData.uniqueItineraries.sort((a, b) => {
                                        // Calculate total trip time for each itinerary
                                        const totalEftA = (a.leg1?.totalEft || 0) + (a.leg2?.totalEft || 0);
                                        const totalEftB = (b.leg1?.totalEft || 0) + (b.leg2?.totalEft || 0);
                                    
                                        // Calculate a combined score (weighting price and time equally, or adjust weights if needed)
                                        const scoreA = a.price.totalAmount + totalEftA; // Combined score for A
                                        const scoreB = b.price.totalAmount + totalEftB; // Combined score for B
                                    
                                        // Sort by combined score (lower is better for both price and time)
                                        return scoreA - scoreB;
                                    });
                                    
                
                                    // Update the flights state
                                    setFlights([...sortedItineraries]);
                
                                    // Update results count
                                    const newItinerariesCount = flightData.uniqueItineraries.length;
                                    setResultsCount((prevCount) => prevCount + newItinerariesCount);
                
                                    // Update progress
                                    const newProgress = Math.min(((resultsCount + newItinerariesCount) / totalExpectedResults) * 100, 100);
                                    setProgress(newProgress);
                
                                    // console.log("Flights updated:", sortedItineraries);
                                }
                            } catch (error) {
                                console.error("Error parsing flight data:", error);
                            }
                
                            // Clear the itinerary buffer after parsing
                            itineraryBuffer = "";
                        }
                    }
                
                    // Clear the buffer after processing all chunks
                    buffer = "";
                }
                

            } catch (e) {
                console.error("Error fetching flight data:", e);
            } finally {
                setIsLoding(false);
            }

        };

        fetchData(); // Call the inner async function
    }, [updatedFormValues]);
    useEffect(() => {
        if (flights.length > 0) {
            setFilteredFlights(flights); // Set filteredFlights with all flights initially
            setFilteredResultsCount(flights.length); // Update filteredResultsCount
        }
    }, [flights]);

    const calculateAndFilterFlights = (criteria) => {
        let sortedFlights = [...filteredFlights];

        const calculateDuration = (flight) => {
            if (flight.legs && flight.legs.length > 0) {
                const firstSegment = flight.legs[0].segments[0];
                const lastSegment = flight.legs[flight.legs.length - 1].segments[flight.legs[flight.legs.length - 1].segments.length - 1];

                if (firstSegment && lastSegment) {
                    // Assuming the departure and arrival times are in ISO format
                    const departureTime = new Date(firstSegment.departureTime).getTime();
                    const arrivalTime = new Date(lastSegment.arrivalTime).getTime();
                    return (arrivalTime - departureTime) / (1000 * 60); // duration in minutes
                }
            }
            return 0; // Default duration if no valid segments are found
        };

        // Assign durations to flights
        sortedFlights = sortedFlights.map(flight => ({
            ...flight,
            calculatedDuration: calculateDuration(flight)
        }));

        switch (criteria) {
            case 'best':
                // Sort by a combination of price and calculated duration
                sortedFlights.sort((a, b) => {
                    if (a.price.totalAmount === b.price.totalAmount) {
                        return a.calculatedDuration - b.calculatedDuration; // Prioritize shorter duration
                    }
                    return a.price.totalAmount - b.price.totalAmount; // Sort by price
                });
                break;
            case 'cheapest':
                // Sort by price only
                sortedFlights.sort((a, b) => a.price.totalAmount - b.price.totalAmount);
                break;
            case 'fastest':
                // Sort by calculated duration
                sortedFlights.sort((a, b) => a.calculatedDuration - b.calculatedDuration);
                break;
            default:
                break;
        }

        setFilteredFlights(sortedFlights);
        setFilteredResultsCount(sortedFlights.length);
    };
    // console.log("FilteredFlights", flights)



    const indexOfLastFlight = currentPage * flightsPerPage;
    const indexOfFirstFlight = indexOfLastFlight - flightsPerPage;
    const currentFlights = filteredFlights.slice(indexOfFirstFlight, indexOfLastFlight);

    // Pagination button click handler
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total pages
    const totalPages = Math.ceil(filteredFlights.length / flightsPerPage);
    // const flights = [
    //   { airline: 'Delta', price: 318, duration: '4h 54m', stops: 'Direct' },
    //   { airline: 'Delta', price: 318, duration: '4h 54m', stops: 'Direct' },
    //   { airline: 'Delta', price: 318, duration: '4h 54m', stops: 'Direct' }
    //   // Add more flights as needed
    // ];

    const calculateAveragePrice = (flights) => {
        if (flights.length === 0) return "0.00"; // Guard clause for empty flights array
        const total = flights.reduce((acc, flight) => acc + flight.price.totalAmount, 0);
        return (total / flights.length).toFixed(2);
    };

    const calculateMinPrice = (flights) => {
        if (flights.length === 0) return "0.00"; // Guard clause for empty flights array
        return Math.min(...flights.map(flight => flight.price.totalAmount));
    };

    const calculateMaxPrice = (flights) => {
        if (flights.length === 0) return "0.00"; // Guard clause for empty flights array
        return Math.max(...flights.map(flight => flight.price.totalAmount));
    };
    
    const handleFilterMobile = () => {
        setMobileFilterOpen(!mobileFilterOpen)
    }

    return (
        <>
        <div onClick={() => setModifyClick(true)}>
        <ModifySearch onClick={modifyClick} initialFormValues={updatedFormValues}/>
        </div>
        <section className="main-body-listing pt-0" >
            <div class="container">
                <div class="row">
                    {currentFlights.length > 0 ? (
                        <>
                    <div class="mob-filter text-center" onClick={handleFilterMobile} data-bs-toggle="modal" data-bs-target="#exampleModal12">
                        {mobileFilterOpen ? (
                        <h6 class="mb-0 dsfhy d-flex align-items-center"><img class="icon-lerft" src={imgfilter} alt="" />Close Filter<p></p></h6>
                        ) : (
                            <h6 class="mb-0 dsfhy d-flex align-items-center"><img class="icon-lerft" src={imgfilter} alt="" />Filter<p></p></h6>
                        )}
                        
                    </div>
                    </>
                    ) : null}
                    <Filters flights={flights} filteredResultsCount={filteredResultsCount} onFilterChange={setFilteredFlights} mobileFilter={mobileFilterOpen} />
                    
                    <div class="col-lg-6 hutd6">
                        <div class="d-flex align-items-center">
                            {isLoding ? (
                                <div class="spinner12 center">
                                    <div class="spinner-blade"></div>
                                    <div class="spinner-blade"></div>
                                    <div class="spinner-blade"></div>
                                    <div class="spinner-blade"></div>
                                    <div class="spinner-blade"></div>
                                    <div class="spinner-blade"></div>
                                    <div class="spinner-blade"></div>
                                    <div class="spinner-blade"></div>
                                    <div class="spinner-blade"></div>
                                    <div class="spinner-blade"></div>
                                    <div class="spinner-blade"></div>
                                    <div class="spinner-blade"></div>
                                </div>
                            ) : null}
                            <p class="mb-0 ms-2">{resultsCount} results</p>
                        </div>
                        <div className="progress mt-3">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${progress}%` }} // Dynamic width based on progress
                                aria-valuenow={progress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                        {/* <!-- ..............compare button start................ --> */}
                        <div class="d-flex mt-3 ur85">
                            <button class="wrh45 activatefd border-0" onClick={() => calculateAndFilterFlights('best')}>
                                <p class="mb-1 rfgrt">Best</p>
                                <p class="mb-0 pricve">${calculateAveragePrice(filteredFlights)}</p>
                            </button>
                            <button class="wrh45" onClick={() => calculateAndFilterFlights('cheapest')}>
                                <p class="mb-1 rfgrt">Cheapest</p>
                                <p class="mb-0 pricve">${calculateMinPrice(filteredFlights)}</p>
                            </button>
                            <button class="wrh45" onClick={() => calculateAndFilterFlights('fastest')}>
                                <p class="mb-1 rfgrt">Fastest</p>
                                <p class="mb-0 pricve">${calculateMaxPrice(filteredFlights)}</p>
                            </button>
                        </div>
                        {/* <!-- ..............compare button end................ --> */}
                        {currentFlights.map((flight, index) => {
                            const flightWithLoading = { ...flight, isLoding, index, allMeta }; // Declare your variable here
                            return (
                                <FlightCard key={index} {...flightWithLoading} />
                            );
                        })}
                    </div>
                    <Sidebar metaFlights={metaFlights} />
                </div>
                {/* Pagination Controls */}
                <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
            </div>
        </section>
        </>
    );
};

export default Listing;