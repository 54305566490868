// Sidebar.js
import React from 'react';
import logo1 from '../../Resources/assest/img/logo1.png'
import san from '../../Resources/assest/img/listing/san.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHotel, faCar } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ metaFlights }) => {
    // console.log("Sidebar", metaFlights)
    return (
        <div class="col-lg-3 hutd674">
            {metaFlights.map((flight, index) => (
            <div class="d-flex align-items-center  justify-content-around drag12 mb-2" key={index}>
                <img class="me-3" src={`https://imgfolders.com/fareslist/sideLogo/${flight.sitename}.png`} alt="" />
                <a href={flight.deeplink} target="_blank" rel="noopener noreferrer">
                    <div class="d-flex justify-content-around align-items-center btis">
                        <button class="button12 ms-0"><span class="text12">View</span><div class="wave12"></div></button>
                    </div>
                </a>
            </div>
            ))}
        </div>
    );
};

export default Sidebar;
